
import {computed, defineComponent, onMounted, ref} from 'vue';
import LoginForm from '@/components/common/LoginForm.vue';
import { useRouter } from 'vue-router';
import {userState} from "@/store/user.store";

export default defineComponent({
  name: 'Login',
  components: {
    'login-form': LoginForm
    // 'ui-text-select': UITextDropdown
  },
  setup() {
    const user = computed(() => userState.value);
    const router = useRouter();
    const canchaSelected = ref('');
    const onLoginDone = () => {
      let route = 'guides.list';
      if (user.value?.role === 'ADMINISTRADOR' || user.value?.role === 'RECEPTOR') route = 'stats';
      if (user.value?.role === 'ASESOR') route = 'asesor.ruma.list';
      router.replace({ name: route });
    };

    

    onMounted(async () => {
      localStorage.clear();
      const body = document.querySelector('body');
      body?.classList.remove('inner-app');
      body?.classList.add('external-app');
    });

    return {
      canchaSelected,
      onLoginDone
    };
  }
});
