import {computed, ref} from "vue";

const isLoading = ref(false);

export const useLoading = () => {
    const setLoadingStatus = (status: boolean) => {
        isLoading.value = status;
    }

    return  {
        setLoadingStatus,
        getLoadingStatus: computed(() => isLoading.value)
    }
}