import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-center absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")

  return (_openBlock(), _createBlock("div", {
    class: {
      'bg-gradient-to-b border-2 border-white text-white text-center rounded-md py-1 cursor-pointer transition-all duration-500 scale-animated shadow hover:shadow-md relative': true,
      'from-green-700 to-green-900': !_ctx.disabled && !_ctx.danger && _ctx.color === 'standard',
      'from-green-400 to-green-600': !_ctx.disabled && !_ctx.danger && _ctx.color === 'success',
      'from-yellow-500 to-yellow-800': !_ctx.disabled && !_ctx.danger && _ctx.color === 'selected',
      'from-red-400 to-red-600': !_ctx.disabled && _ctx.danger,
      'pointer-events-none from-gray-300 to-gray-400': _ctx.disabled,
      'pointer-events-none select-none': _ctx.loading,
       'my-1': !_ctx.noMargin
    }
  }, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock("div", _hoisted_1, [
          _createVNode("img", {
            src: require('@/assets/icons/spinner.svg'),
            alt: "",
            class: "w-5 h-5 animate-spin"
          }, null, 8, ["src"])
        ]))
      : _createCommentVNode("", true),
    _createVNode("div", {
      class: {
        'select-none antialiased font-semibold flex justify-center items-center px-4 h-full': true,
        'py-1': !_ctx.label,
        'text-transparent': _ctx.loading
      }
    }, [
      (Array.isArray(_ctx.icon) && _ctx.icon.length > 0)
        ? (_openBlock(), _createBlock(_component_fa_icon, {
            key: 0,
            icon: _ctx.icon,
            class: _ctx.label ? 'mr-1' : ''
          }, null, 8, ["icon", "class"]))
        : _createCommentVNode("", true),
      (_ctx.label)
        ? (_openBlock(), _createBlock("span", _hoisted_2, _toDisplayString(_ctx.label.toUpperCase()), 1))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}