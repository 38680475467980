
import { defineComponent } from 'vue';
import UIToast from './components/UI/UIToast.vue';

export default defineComponent({
  name: 'App',
  components: {
    'ui-toast': UIToast,
  },
});
