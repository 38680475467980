
import { formatDateDash, inputValidation } from '@/utils';
import {
  ComponentPublicInstance,
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch
} from 'vue';

export default defineComponent({
  name: 'UITextInput',
  props: {
    noMargin: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: 'placeholder'
    },
    value: {
      type: [String, Number],
      default: null
    },
    validations: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    decimals: {
      type: String,
      default: '1'
    },
    validationValues: {
      type: Array,
      default: () => []
    },
    upperCase: {
      type: Boolean,
      default: false
    },
    dni: {
      type: Boolean,
      default: false
    },
    date: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: 255
    }
  },
  emits: ['update:value', 'focus'],
  setup(props, { emit }) {
    const isFocus = ref(false);
    const isValid = ref(true);
    const errorMessage = ref('');
    const ring = computed(() =>
      isValid.value ? 'ring-green-200' : 'ring-red-500'
    );
    const labelColor = computed(() => {
      if (isFocus.value) return 'bg-green-800';
      return isValid.value ? 'bg-green-500' : 'bg-red-500';
    });

    const onBlur = () => {
      isFocus.value = false;
      //if (props.type === 'number' && !props.value) emit('update:value', 0);
      validationFn();
    };

    const validationFn = () => {
      isValid.value = true;
      if (props.validations.length === 0) return true;
      props.validations.forEach((v: string) => {
        const validation = inputValidation(props.value+'', v, props.validationValues);
        isValid.value = validation.valid;
        errorMessage.value = validation.errorMessage;
      });
      if (isValid.value) errorMessage.value = '';
      if (!isValid.value) console.log(props.placeholder);
      return isValid.value;
    };

    const trimNoDigits = (evt:string) => {
      let str = '';
      evt.split('').forEach(el => {
        const n = ['0','1','2','3','4','5','6','7','8','9'];
        if (n.includes(el)) str+=el;
        if (el === '.' || el === ',') str+='.';
      });
      return +str;
    };
    const addDash = (val:string) => {
      const splitted = val.replace(/-/, '').replace(/\./g, '').split('').reverse();
      const first = splitted.shift();
      return [first, '-', ...splitted].reverse().join('');

    };

    const emitValue = (evt:any) => {
      props.upperCase && (evt = evt.toString().toUpperCase());
      props.date && (evt = formatDateDash(evt));
      props.dni && evt.length > 4 && (evt = addDash(evt));
      // props.type === 'number' && (evt = trimNoDigits(evt+''));
      if (props.type === 'number') {
        evt = trimNoDigits(evt);
      }
      emit('update:value', evt);
    };

    const maxLengthCalculated = computed(() => {
      if (props.date) return 10;
      if (props.maxLength < 255) return props.maxLength;
      return 255;
    });

    const showPassword = ref(false);
    const inputType = computed(() => {
      if (props.type === 'password')
        return showPassword.value ? 'text' : 'password';
      if (props.type === 'number') return 'text';
      return props.type;
    });

    const inputRef = ref<ComponentPublicInstance<HTMLInputElement> | null>(
      null
    );
    onMounted(() => {
      inputRef.value && (inputRef.value.disabled = props.disabled);
    });
    watch(
      () => props.disabled,
      (val) => {
        inputRef.value && (inputRef.value.disabled = val);
      }
    );

    const onFocusFn = () => {
      isFocus.value = true;
      emit('focus');
      if (props.type === 'number') emit('update:value', '');
    };

    return {
      isValid,
      ring,
      validationFn,
      errorMessage,
      showPassword,
      inputType,
      isFocus,
      onBlur,
      labelColor,
      inputRef,
      emitValue,
      onFocusFn,
      maxLengthCalculated
    };
  }
});
