
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'UIModal',
  props: {
    width: {
      type: String,
      default: '300px'
    },
    large: {
      type: Boolean,
      default: false
    },
    modalState: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modalState'],
  setup(props, { emit }) {
    const innerModal = ref(false);
    const innerModalDismiss = () => {
      innerModal.value = false;
      setTimeout(() => emit('update:modalState', false), 300);
    };
    watch(() => props.modalState, () => {
      const html = document.querySelector('html');
      const body = document.querySelector('body');
      if (props.modalState) {
        html?.classList.add('overflow-hidden');
        body?.classList.add('overflow-hidden');
        setTimeout(() => innerModal.value = true, 200);
      } else {
        html?.classList.remove('overflow-hidden');
        body?.classList.remove('overflow-hidden');
      }
    });

    return {
      innerModal,
      innerModalDismiss
    };
  }
});
