import moment from 'moment-timezone';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function validateForm(refs: any[] = []): boolean {
  let validForm = true;
  refs.forEach((el) => {
    const valid = el.value?.validationFn();
    if (!valid) validForm = false;
  });
  return validForm;
}

function emailValidator(val: string): boolean {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(val);
}

function isValidDate(date: number) {
  return moment(date).isValid();
}

export function dateToTimestamp(time: string): number {
  const d = time.split('-');
  if (d.join('').split('').length < 8) return 0;
  const newDate = moment(d.reverse().join('-')).format('YYYY-MM-DD');
  return +moment(newDate).format('x');
}

function formatDate(date: string): { valid: boolean; formatted: number } {
  const newDate = dateToTimestamp(date);
  const valid = moment(newDate).isValid();
  return { valid, formatted: valid ? +newDate : 0 };
}

function dateValidator( // DD-MM-AAAA
  date: string
  // isTimestamp = false,
  // mustBeBeforeToday = false
): boolean {
  const check = formatDate(date);
  if (check.valid) return isValidDate(check.formatted);
  return false;
  // if (isTimestamp) return moment(time).isValid();

  // const d = time.split('-');
  // if (d.join('').split('').length < 8) return false;
  // const newDate = moment(`${d[2]}-${d[1]}-${d[0]}`).format();
  // if (!moment(newDate).isValid()) return false;
  // if (mustBeBeforeToday) {
  //   const today = moment().format();
  //   return !moment(newDate).isBefore(today);
  // }
  // return true;
}

function dateIsFuture(date: string): boolean {
  const check = formatDate(date);
  const now = moment().format();
  if (check.valid) return moment(check.formatted).isAfter(now);
  return true;
}

export function dateBefore30(now: string, date: string): boolean {
  const check = formatDate(date);
  const a = now === '0' ? moment().format() : +now;
  const limit = moment(a).subtract(31, 'days').format();
  if (check.valid) return moment(check.formatted).isBefore(limit);
  return true;
}

export function formatDateDash(date: string): string {
  const splitted = date.split('');
  if (splitted.length === 3 && splitted[2] !== '-') {
    const p = splitted.pop();
    splitted.push('-');
    splitted.push(p + '');
  }
  if (splitted.length === 6 && splitted[5] !== '-') {
    const p = splitted.pop();
    splitted.push('-');
    splitted.push(p + '');
  }
  return splitted.join('').slice(0, 10);
}

export function rutCleaner(rut: string, soft = true): string {
  let r = rut.replace(/\./g, '').replace(/\s/g, '').replace(/^0+/, '').toUpperCase();
  if (!soft) r = r.replace(/-/, '');
  return r;
}

// include dots n dash
export function rutFormatter(v: string): string {
  const val = rutCleaner(v, false);
  const s = val.toString().split('').reverse().join('');
  const dv = s[0];
  const body3 = s.slice(1, 4).split('').reverse().join('');
  const body2 = s.slice(4, 7).split('').reverse().join('');
  const body1 = s.slice(7).split('').reverse().join('');
  return `${body1}.${body2}.${body3}-${dv}`;
}

function rutValidator(rut: string) {
  const r = rutCleaner(rut);
  if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(r)) return false;
  const tmp = r.split('-');
  let digv = tmp[1];
  const ini = tmp[0];
  if (digv == 'k') digv = 'K';
  return dv(ini).toString() === digv.toString();
}

function dv(T: any) {
  let M = 0,
    S = 1;
  for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
  return S ? S - 1 : 'K';
}

function numberValidator(val: string): boolean {
  return !isNaN(+val);
}

export const inputValidation = (value: string, validation: string, validationValues: any[] = []): { valid: boolean; errorMessage: string } => {
  let valid = true;
  let errorMessage = '';
  switch (validation) {
    case 'required':
      if (!value) valid = false;
      if (!(value+'').length) valid = false;
      !valid && (errorMessage = 'Campo Requerido');
      break;
    case 'dni':
      if (value && value.includes('-')) {
        const v = rutValidator(value);
        if (!v) valid = false;
        !valid && (errorMessage = 'Rut no válido');
      } else if (value) {
        valid = false;
        errorMessage = 'Falta guión';
      }
      break;
    case 'email':
      valid = emailValidator(value);
      !valid && (errorMessage = 'Email no válido');
      break;
    case 'date':
      valid = dateValidator(value);
      !valid && (errorMessage = 'Fecha no válida');
      break;
    case 'no-future':
      if (!value) {
        valid = false;
        !valid && (errorMessage = 'Fecha Requerida');
      } else if (value && dateValidator(value)) {
        valid = !dateIsFuture(value);
        !valid && (errorMessage = 'Fecha debe ser anterior');
      } else {
        valid = false;
        !valid && (errorMessage = 'Fecha no válida');
      }
      break;
    case 'before-30':
      if (dateValidator(value)) {
        valid = !dateBefore30(validationValues[0], value);
        !valid && (errorMessage = 'Fecha no permitida');
      } else {
        valid = false;
        !valid && (errorMessage = 'Fecha no válida');
      }
      break;
    case 'length8':
      valid = value.trim().length > 7;
      !valid && (errorMessage = 'Requiere al menos 8 caracteres');
      break;
    case 'length10':
      valid = value.trim().length > 9;
      !valid && (errorMessage = 'Requiere al menos 10 caracteres');
      break;
    case 'length-4':
      valid = (value+'').trim().length <= 4;
      !valid && (errorMessage = 'Demasiado largo');
      break;
    case 'length-5':
      valid = (value+'').trim().length <= 5;
      !valid && (errorMessage = 'Demasiado largo');
      break;
    case 'length-10':
      valid = (value+'').trim().length <= 20;
      !valid && (errorMessage = 'Demasiado largo');
      break;
    case 'length-20':
      valid = (value+'').trim().length <= 20;
      !valid && (errorMessage = 'Demasiado largo');
      break;
    case 'number':
      if (!value.split('').length) {
        valid = false;
        !valid && (errorMessage = 'Debe ser número');
        break;
      }
      // eslint-disable-next-line no-case-declarations
      const validNumber = numberValidator(value);
      if (validNumber) {
        valid = +value >= 0;
        !valid && (errorMessage = 'Debe ser número positivo');
        if (valid && validationValues[0]) {
          // const validate = +validationValues[0];
          // valid = +value < validate;
          // !valid && (errorMessage = 'Número demasiado alto');
          const valueStr = (value + '').split('.');
          const validationStr = (validationValues + '').split('.');
          if (valueStr[0].length > validationStr[0].length) {
            valid = false;
            errorMessage = 'Número demasiado alto';
            break;
          }
          if (valueStr[1] && valueStr[1].length > validationStr[1].length) {
            valid = false;
            errorMessage = 'Demasiados decimales';
            break;
          }
        }
      } else {
        valid = false;
        !valid && (errorMessage = 'Debe ser número');
      }
      break;
    case 'values':
      valid = validationValues.includes(value);
      !valid && (errorMessage = 'Valor o Formato Incorrecto');
      break;
    case 'not-zero':
      valid = +value >= 0;
      !valid && (errorMessage = 'Valor incorrecto');
      break;
    default:
      valid = true;
      break;
  }
  return { valid, errorMessage };
};

export function cleanSource<T>(param: T): T {
  return JSON.parse(JSON.stringify(param));
}

export const numberDecimal = new Intl.NumberFormat('es-CL', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
