
import { canchaState } from '@/store/cancha.store';
import { userState } from '@/store/user.store';
import { computed, defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import UIModal from '@/components/UI/UIModal.vue';
import UIButton from '@/components/UI/UIButton.vue';
import LoginForm from '@/components/common/LoginForm.vue';
import moment from 'moment';
import { SocketIO } from '@/utils/socketUtils';
import { makeRequest, METHODS } from '@/utils/apiUtils';
import { IMantenedores, setMantenedores } from '@/store/mantenedores.store';
import { ICancha, IProducto } from '@/models/Mantenedores';
import {useLoading} from "@/hooks/useLoading";

export default defineComponent({
  name: 'NavLayout',
  components: {
    'ui-modal': UIModal,
    'ui-button': UIButton,
    'login-form': LoginForm
  },
  setup() {
    const socket = SocketIO.getInstance();
    const modalState = ref(false);
    const modalStateLogin = ref(false);
    const router = useRouter();
    const routeHandler = (routeName: string) => {
      if (!routeName) return;
      router.push({ name: routeName });
    };

    const menu = ref<any[]>([
      {
        name: 'Guías',
        route: 'guides.list',
        children: [{ name: 'Listado', route: 'guides.list', order: 0 }]
      },
      {
        name: 'Rumas',
        route: 'rumas.list',
        children: [{ name: 'Listado', route: 'rumas.list', children: [] }]
      }
    ]);

    const { getLoadingStatus } = useLoading();
    const user = computed(() => userState.value);
    const cancha = computed(() => canchaState.value);
    const signOut = () => {
      router.push('/');
    };
    const onLoginDone = () => {
      modalStateLogin.value = false;
    };

    const actual = ref(0);
    const interval = setInterval(() => {
      actual.value = +moment().format('x');
    }, 1000 * 60 * 5); // 5 min
    const userData = computed(() => {
      return userState.value;
    });
    watch(
      () => actual.value,
      (val) => {
        if (val && userData.value) {
          // console.log('-----');
          // console.log('actual', moment(val).format());
          // console.log('expira', moment(userData.value.sessionExp * 1000).format());
          // console.log('check', moment((userData.value.sessionExp * 1000)- (31 * 60000)).format());
          // console.log('-----');
          if (val > userData.value.sessionExp * 1000 - 31 * 60 * 1000) {
            modalStateLogin.value = true;
          }
        }
      }
    );
    const mustReload = ref(false);
    const notifyNewData = () => {
      console.log('NEW DATA !');
      mustReload.value = true;
    };

    function onCanchaMod(canchas: ICancha[]) {
      const mantenedores = localStorage.getItem('pehuen:mantenedores');
      if (mantenedores) {
        const m: IMantenedores = JSON.parse(mantenedores);
        m.canchas = canchas;
        setMantenedores(m);
      }
    }

    function onProductosMod(productos: IProducto[]) {
      const mantenedores = localStorage.getItem('pehuen:mantenedores');
      if (mantenedores) {
        const m: IMantenedores = JSON.parse(mantenedores);
        m.productos = productos;
        setMantenedores(m);
      }
    }

    onMounted(async () => {
      socket.connectToServer();
      console.log('LAYOUT MOUNTED');
      const request = await makeRequest<IMantenedores>(METHODS.GET, 'mantenedores', true);
      request.payload && setMantenedores(request.payload);
      if (user.value?.role === 'ADMINISTRADOR') {
        menu.value[0].children.push(
          ...[
            { name: 'Ajustes', route: 'ajustes.list', order: 1 },
            { name: 'Importar MII', route: 'mii.importer', order: 3 }
          ]
        );
        menu.value.push({
          name: 'Canchas',
          route: 'canchas.list'
        });
      }
      if (user.value?.role === 'ADMINISTRADOR') {
        menu.value.push({
          name: 'Productos',
          route: 'product.list',
          children: [
            { name: 'Lista', route: 'product.list', order: 1 },
            { name: 'Crear', route: 'product.create', order: 2 }
          ]
        });
      }
      // if (user.value?.role !== 'RECEPTOR') {
      menu.value.push({ name: 'Reportes', route: 'excel.reports', children: [] });
      // }
      if (user.value?.role === 'ADMINISTRADOR' || user.value?.role === 'SUPERVISOR') {
        menu.value.push({ name: 'Usuarios', route: 'users.list', children: [] });
      }
      if (user.value?.role !== 'CONSULTOR' && user.value?.role !== 'SUPERVISOR') {
        menu.value[0].children.unshift({ name: 'Crear Guía', route: 'guides.create', order: 2 });
        menu.value[1].children.unshift({ name: 'Crear Ruma', route: 'rumas.create', children: [] });
      }
      if (user.value?.role !== 'ADMINISTRADOR') {
        menu.value.push({ name: 'Mi Contraseña', route: 'user.password', children: [] });
      }
      menu.value.unshift({
        name: 'Inicio',
        route: 'stats'
      });
      if (user.value?.role === 'ASESOR') {
        menu.value = [
          {
            name: 'Estadísticas',
            route: 'asesor.stats',
            children: []
        },
        {
          name: 'Rumas',
          route: 'asesor.ruma.list',
          children: []
        }
      ];

      }


      // menu.value.map((el) => {
      //   el.children = el.children.sort((a: any, b: any) => a.order - b.order);
      // });
      const html = document.querySelector('html');
      const body = document.querySelector('body');
      html?.classList.remove('overflow-hidden');
      body?.classList.remove('overflow-hidden');
      body?.classList.remove('external-app');
      body?.classList.add('inner-app');
      const roomEvents = [{ evt: 'new-mii', cb: notifyNewData }];
      await socket.joinRoom('files', roomEvents);
      await socket.joinRoom('canchas-updated', [{ evt: 'canchas', cb: onCanchaMod }]);
      await socket.joinRoom('productos-updated', [{ evt: 'productos', cb: onProductosMod }]);
    });
    onBeforeUnmount(async () => {
      clearInterval(interval);
      await socket.leaveRoom('files');
      await socket.leaveRoom('canchas-updated');
      socket.disconnectServer();
    });

    return {
      menu,
      routeHandler,
      user,
      cancha,
      signOut,
      modalState,
      modalStateLogin,
      onLoginDone,
      mustReload,
      getLoadingStatus
    };
  }
});
