import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router';
import Login from '../views/Login.vue';
import NavLayout from '../layouts/NavLayout.vue';
import { makeRequest, METHODS } from '@/utils/apiUtils';
import { setMantenedores } from '@/store/mantenedores.store';
import { setUser } from '@/store/user.store';
import { IClient } from '@/models/Client';
import { setCancha } from '@/store/cancha.store';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      public: true
    }
  },
  {
    path: '/app/',
    component: NavLayout,
    redirect: '/app/guides',
    children: [
      {
        path: 'stats',
        name: 'stats',
        component: () =>
            import(/* webpackChunkName: "guide-list" */ '../views/Stats.vue'),
        meta: {
          routeName: 'Estadísticas'
        }
      },
      {
        path: 'guides',
        name: 'guides.list',
        component: () =>
          import(/* webpackChunkName: "guide-list" */ '../views/guides/List.vue'),
        meta: {
          routeName: 'Listado Guías'
        }
      },
      {
        path: 'ajustes',
        name: 'ajustes.list',
        component: () =>
          import(/* webpackChunkName: "guide-list" */ '../views/Ajustes.vue'),
        meta: {
          routeName: 'Listado Ajustes'
        }
      },
      {
        path: 'canchas',
        name: 'canchas.list',
        component: () =>
          import(/* webpackChunkName: "guide-list" */ '../views/Canchas.vue'),
        meta: {
          routeName: 'Canchas'
        }
      },
      {
        path: 'guides-create',
        name: 'guides.create',
        component: () =>
          import(/* webpackChunkName: "guide-create" */ '../views/guides/Create.vue'),
        meta: {
          routeName: 'Crear Guía'
        }
      },
      {
        path: 'importer',
        name: 'mii.importer',
        component: () =>
          import(/* webpackChunkName: "mii-imorter" */ '../views/guides/Importer.vue'),
        meta: {
          routeName: 'Importar Mii'
        }
      },
      {
        path: 'rumas',
        name: 'rumas.list',
        component: () =>
          import(/* webpackChunkName: "ruma-list" */ '../views/rumas/List.vue'),
        meta: {
          routeName: 'Listado Rumas'
        }
      },
      {
        path: 'rumas-create',
        name: 'rumas.create',
        component: () =>
          import(/* webpackChunkName: "ruma-create" */ '../views/rumas/Create.vue'),
        meta: {
          routeName: 'Crear Ruma'
        }
      },
      {
        path: 'reports',
        name: 'excel.reports',
        component: () =>
          import(/* webpackChunkName: "excel-reports" */ '../views/Reports.vue'),
        meta: {
          routeName: 'Reportes'
        }
      },
      {
        path: 'users',
        name: 'users.list',
        component: () =>
          import(/* webpackChunkName: "user-list" */ '../views/Users.vue'),
        meta: {
          routeName: 'Usuarios'
        }
      },
      {
        path: 'password',
        name: 'user.password',
        component: () =>
          import(/* webpackChunkName: "user-password" */ '../views/Password.vue'),
        meta: {
          routeName: 'Contraseña'
        }
      },
      {
        path: 'productos',
        name: 'product.list',
        component: () =>
          import(/* webpackChunkName: "product-list" */ '../views/products/List.vue'),
        meta: {
          routeName: 'Listado Productos'
        }
      },
      {
        path: 'productos-create',
        name: 'product.create',
        component: () =>
          import(/* webpackChunkName: "product-create" */ '../views/products/Create.vue'),
        meta: {
          routeName: 'Crear Producto'
        }
      },
      {
        path: 'productos-edit/:id',
        name: 'product.edit',
        component: () =>
          import(/* webpackChunkName: "product-edit" */ '../views/products/Edit.vue'),
        meta: {
          routeName: 'Editar Producto'
        }
      },
      {
        path: 'asesor-stats',
        name: 'asesor.stats',
        component: () =>
            import(/* webpackChunkName: "asesor-stats" */ '../views/asesor/Stats.vue'),
        meta: {
          routeName: 'Rumas'
        }
      },
      {
        path: 'asesor-ruma-list',
        name: 'asesor.ruma.list',
        component: () =>
            import(/* webpackChunkName: "asesor-ruma-list" */ '../views/asesor/Rumas.vue'),
        meta: {
          routeName: 'Rumas'
        }
      },
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // createWebHashHistory(), // createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, _from, next) => {
  if (!to.meta.public) {
    const request = await makeRequest<IClient>(METHODS.GET, '/auth/me', true);
    if (request.ok) {
      request.payload && setUser(request.payload);
      const mantenedores = localStorage.getItem('pehuen:mantenedores');
      mantenedores && setMantenedores(JSON.parse(mantenedores));
      // const c:any = JSON.parse(localStorage.getItem('pehuen:cancha')+'');
      request.payload?.cancha && setCancha(request.payload?.cancha);
      next();
    } else {
      localStorage.clear();
      next('/');
    }
  } else {
    next();
  }
});

export default router;
