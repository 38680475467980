import { IClient } from '@/models/Client';
import { createStore } from '@harlem/core';

export interface IUsersState {
  user: IClient | null;
}

const STATE: IUsersState = {
  user: null
};

const { getter, mutation } = createStore('user', STATE);

export const setUser = mutation<IClient>('setUser', (state, payload) => {
  state.user = payload;
});

export const userState = getter('userState', state => state.user);
export const userCanchaState = getter('userCanchaState', state => state.user?.cancha);
export const userRoleState = getter('userRoleState', state => state.user?.role);