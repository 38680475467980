import { createStore } from '@harlem/core';
import {
  ICalidadProducto,
  ICancha,
  IComuna,
  IEspecie,
  IEstadoGuia,
  IEstadoMadera,
  IEstadoRuma,
  IFactorCorteza,
  IGrupoProducto,
  IIntervencion,
  IMovimiento,
  IProcedencia,
  IProducto,
  IUnidadMedida,
  IZona
} from '../models/Mantenedores';

export interface IMantenedores {
  especies: IEspecie[];
  estadoGuias: IEstadoGuia[];
  estadoMaderas: IEstadoMadera[];
  estadoRumas: IEstadoRuma[];
  intervenciones: IIntervencion[];
  movimientos: IMovimiento[];
  procedencias: IProcedencia[];
  unidadMedidas: IUnidadMedida[];
  zonas: IZona[];
  comunas: IComuna[];
  calidadProductos: ICalidadProducto[];
  grupoProductos: IGrupoProducto[];
  productos: IProducto[];
  factorCorteza: IFactorCorteza[];
  canchas: ICancha[];
}

interface IMantenedoresState {
  especies: IEspecie[];
  estadoGuias: IEstadoGuia[];
  estadoMaderas: IEstadoMadera[];
  estadoRumas: IEstadoRuma[];
  intervenciones: IIntervencion[];
  movimientos: IMovimiento[];
  procedencias: IProcedencia[];
  unidadMedidas: IUnidadMedida[];
  zonas: IZona[];
  comunas: IComuna[];
  calidadProductos: ICalidadProducto[];
  grupoProductos: IGrupoProducto[];
  productos: IProducto[];
  factorCorteza: IFactorCorteza[];
  canchas: ICancha[];
}

const STATE: IMantenedoresState = {
  especies: [],
  estadoGuias: [],
  estadoMaderas: [],
  estadoRumas: [],
  intervenciones: [],
  movimientos: [],
  procedencias: [],
  unidadMedidas: [],
  zonas: [],
  comunas: [],
  calidadProductos: [],
  grupoProductos: [],
  productos: [],
  factorCorteza: [],
  canchas: []
};

const { getter, mutation } = createStore('mantenedores', STATE);

export const setMantenedores = mutation<IMantenedores>(
  'setMantenedores',
  (state, payload) => {
    const {
      especies,
      estadoGuias,
      estadoMaderas,
      estadoRumas,
      intervenciones,
      movimientos,
      procedencias,
      unidadMedidas,
      zonas,
      comunas,
      calidadProductos,
      grupoProductos,
      productos,
      factorCorteza,
      canchas
    } = payload;
    especies && (state.especies = especies);
    estadoGuias && (state.estadoGuias = estadoGuias);
    estadoMaderas && (state.estadoMaderas = estadoMaderas);
    estadoRumas && (state.estadoRumas = estadoRumas);
    intervenciones && (state.intervenciones = intervenciones);
    movimientos && (state.movimientos = movimientos);
    procedencias && (state.procedencias = procedencias);
    unidadMedidas && (state.unidadMedidas = unidadMedidas);
    zonas && (state.zonas = zonas);
    comunas && (state.comunas = comunas.map(el => {
      el.descripcion = el.descripcion.toUpperCase();
      return el;
    }).sort((a,b) => {
      if(a.descripcion < b.descripcion) return -1;
      if(a.descripcion > b.descripcion) return 1;
      return 0;
    }));
    productos && (state.productos = productos);
    calidadProductos && (state.calidadProductos = calidadProductos);
    grupoProductos && (state.grupoProductos = grupoProductos);
    factorCorteza && (state.factorCorteza = factorCorteza);
    canchas && (state.canchas = canchas.filter((el: any) => el.activo));
  }
);

export const especiesState = getter('especiesState', (state) => state.especies);
export const estadoGuiasState = getter('estadoGuiasState', (state) => state.estadoGuias);
export const estadoMaderasState = getter('estadoMaderasState', (state) => state.estadoMaderas);
export const estadoRumasState = getter('estadoRumasState', (state) => state.estadoRumas);
export const intervencionesState = getter('intervencionesState', (state) => state.intervenciones);
export const movimientosState = getter('movimientosState', (state) => state.movimientos);
export const procedenciasState = getter('procedenciasState', (state) => state.procedencias);
export const unidadMedidasState = getter('unidadMedidasState', (state) => state.unidadMedidas);
export const zonasState = getter('zonasState', (state) => state.zonas);
export const comunasState = getter('comunasState', (state) => state.comunas);
export const calidadProductosState = getter('calidadProductosState', (state) => state.calidadProductos);
export const grupoProductosState = getter('grupoProductosState', (state) => state.grupoProductos);
export const productosState = getter('productosState', (state) => state.productos);
export const factorCortezaState = getter('factorCortezaState', (state) => state.factorCorteza);
export const canchasState = getter('canchasState', (state) => state.canchas);
export const getProcedenciaNyId = (id: string) => {
  return procedenciasState.value.find((el: IProcedencia) => el._id === id);
};
export const getProductoById = (id: string) => {
  return productosState.value.find((el: IProducto) => el._id === id);
};
export const getCanchaById = (id: string) => {
  return canchasState.value.find((el: ICancha) => el._id === id);
};
