
import { ComponentPublicInstance, defineComponent, ref } from 'vue';
import UITextInput from '@/components/UI/UITextInput.vue';
import UIButton from '@/components/UI/UIButton.vue';
import { setMantenedores } from '@/store/mantenedores.store';
import { setUser } from '@/store/user.store';
import { setCancha } from '@/store/cancha.store';
import { showToast } from '@/hooks/useToast';
import { rutCleaner } from '@/utils';
import { makeRequest, METHODS } from '@/utils/apiUtils';
import { IClientLogin } from '@/models/Client';

export default defineComponent({
  name: 'LoginComp',
  components: {
    'ui-text-input': UITextInput,
    'ui-button': UIButton,
    // 'ui-text-select': UITextDropdown
  },
  props: {
    onLoginFn: { 
      type: Function,
      default: () => console.log('LOGIN DONE !')
    }
  },
  setup(props) {
    const dni = ref('');
    const password = ref('');
    const logginIn = ref(false);
    const dniRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(
      null
    );
    const passwordRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(
      null
    );
    const loginFn = async () => {
      try {
        logginIn.value = true;
        const request = await makeRequest<IClientLogin>(
          METHODS.POST,
          '/auth/login',
          false,
          {
            rut: rutCleaner(dni.value, false),
            password: password.value
          }
        );
        if (request.ok && request.payload) {
          localStorage.setItem('token', request.payload.token.accessToken);
          const { _id, email, role, createdAt, updatedAt, nombre, rut, cancha, sessionExp } = request.payload;
          setUser({ _id, email, role, createdAt, updatedAt, nombre, rut, cancha, sessionExp });
          setMantenedores(request.payload.mantenedores);
          cancha && setCancha(cancha);
          props.onLoginFn();
        } else {
          showToast('ERROR', request.errors+'');
          console.log(request.errors);
        }
      } catch (error) {
        console.log(error);
      } finally {
        logginIn.value = false;
      }
    };
    return {
      dni,
      password,
      dniRef,
      passwordRef,
      loginFn,
      logginIn
    };
  },
});
