import axios from 'axios';
import {useLoading} from "@/hooks/useLoading";

const baseUrl = process.env.NODE_ENV === 'production' ? 'https://scpehuen.cl/api' : `http://localhost:5001/api`;
axios.defaults.baseURL = baseUrl;

export enum METHODS {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  DELETE = 'DELETE'
}

export interface ApiResponse<T> {
  ok: boolean;
  status: number;
  payload?: T;
  errors?: string;
}

const { setLoadingStatus, getLoadingStatus } = useLoading();

export async function makeRequest<T>(
  method: METHODS,
  url: string,
  auth = true,
  body: { [k: string]: any } = {},
  params: { [k: string]: any } = {}
): Promise<ApiResponse<T>> {
  try {
    setLoadingStatus(true);
    const requestOpts = {
      method: METHODS[method],
      url,
      data: JSON.parse(JSON.stringify(body)),
      headers: {},
      params
    };
    if (auth) {
      const token = localStorage.getItem('token');
      requestOpts.headers = { Authorization: `Bearer ${token}` };
    }
    const response = await axios(requestOpts);
    const { status, data } = response;
    return { ok: true, status: status, payload: data.payload };
  } catch (error: any) {
    const { status, data } = error.response;
    return { ok: false, status, errors: data.errors };
  } finally {
    setLoadingStatus(false);
  }
}

export async function requestFile<T>(
  url: string,
  filename: string
): Promise<ApiResponse<T>> {
  try {
    const token = localStorage.getItem('token');
    const request = await axios({
      method: METHODS.GET,
      url,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const type = request.headers['content-type'];
    const urlLink = window.URL.createObjectURL(
      new Blob([request.data], { type })
    );
    const link = document.createElement('a');
    link.href = urlLink;
    link.setAttribute('download', `${filename}.csv`);
    document.body.appendChild(link);
    link.click();

    return { ok: true, status: 200 };
  } catch (error: any) {
    const { status } = error.response;
    return { ok: false, status, errors: 'Archivo no encontrado' };
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function sendFile<T>(url:string, file: any): Promise<ApiResponse<T>> {
  try {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('mii', file);
    await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    });
    return { ok: true, status: 200 };
  } catch (error:any) {
    console.log(error);
    const { status } = error.response;
    return { ok: false, status, errors: 'ERROR' };
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function sendFileToServer<T>(url:string, file: any, name: string): Promise<ApiResponse<T>> {
  try {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append(name, file);
    const res = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    });
    return { ok: true, status: 200, payload: res.data};
  } catch (error:any) {
    console.log(error);
    const { status } = error.response;
    return { ok: false, status, errors: 'ERROR' };
  }
}

export function rumaImageUrl (rumaId: string) {
  return `${baseUrl}/ruma/image/`+ rumaId;
}
