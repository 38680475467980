import { computed, ref } from 'vue';

const state = ref(false);
const clr = ref('#9BA09B');
const msg = ref('Toast Message !');
const toastStatus = computed(() => ({
  state: state.value,
  color: clr.value,
  message: msg.value
}));
const showToast = (color: 'SUCCESS' | 'ERROR' | 'ALERT' | 'INFO', message: string, autoDismiss = true): void => {
  msg.value = message;
  switch (color) {
    case 'SUCCESS':
      clr.value = '#1B9B11';
      break;
    case 'ERROR':
      clr.value = '#B01010';
      break;
    case 'ALERT':
      clr.value = '#D3D32F';
      break;
    case 'INFO':
      clr.value = '#1684CB';
      break;
    default:
      clr.value = '#9BA09B';
  }
  state.value = true;
  if (autoDismiss) {
    setTimeout(() => (state.value = false), 4000);
  }
};
const closeToast = (): void => {
  state.value = false;
};

export { toastStatus, showToast, closeToast };
