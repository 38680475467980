import { ICancha } from '@/models/Mantenedores';
import { createStore } from '@harlem/core';
import { canchasState } from './mantenedores.store';

export interface ICanchaState {
  cancha: ICancha | null;
}

const STATE: ICanchaState = {
  cancha: null
};

const { getter, mutation } = createStore('cancha', STATE);

export const setCancha = mutation<string>('setCancha', (state, payload) => {
  if (payload) {
    const cancha = canchasState.value.find(el => el._id === payload);
    if (payload === 'ALL') state.cancha = { _id: 'ALL', descripcion: 'TODAS LAS CANCHAS', activo: true };
    else state.cancha = cancha ? cancha : null;
  } else {
    state.cancha = null;
  }
});

export const canchaState = getter('canchaState', state => state.cancha);