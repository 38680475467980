<template>
  <div class="toast-container">
    <transition name="toast" appear>
      <div
        v-show="toastStatus.state"
        class="
          flex
          justify-start
          items-center
          text-white
          fixed
          top-0
          md:top-2
          left-1/2
          md:rounded-md
          px-4
          py-2
          shadow-md
          transform
          -translate-x-1/2
          w-screen
          md:w-1/3
          lg:w-1/4
        "
        :style="`z-index:9999999;background-color:${toastStatus.color};`"
      >
        <p class="text-left font-light select-none">
          {{ toastStatus.message }}
        </p>
      </div>
    </transition>
  </div>
</template>
<script>
import { toastStatus } from '../../hooks/useToast';

export default {
  name: 'UIToast',
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    return {
      toastStatus
    };
  }
};
</script>
<style scoped>
.toast-enter-active,
.toast-leave-active {
  transition: transform 0.4s ease-in-out;
}
.toast-enter-from {
  transform: translate(-50%, -100%);
}
.toast-leave-to {
  transform: translate(-50%, -100%);
}
</style>
