import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_text_input = _resolveComponent("ui-text-input")
  const _component_ui_button = _resolveComponent("ui-button")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_ui_text_input, {
        ref: "dniRef",
        value: _ctx.dni,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.dni = $event)),
        placeholder: "Rut",
        validations: ['dni'],
        dni: true,
        "max-length": 10,
        upperCase: true
      }, null, 8, ["value"])
    ]),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_ui_text_input, {
        ref: "passwordRef",
        value: _ctx.password,
        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_ctx.password = $event)),
        placeholder: "Contraseña",
        type: "password",
        validations: ['required']
      }, null, 8, ["value"])
    ]),
    _createVNode(_component_ui_button, {
      label: "Ingresar",
      onClick: _ctx.loginFn,
      loading: _ctx.logginIn
    }, null, 8, ["onClick", "loading"])
  ], 64))
}