
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UIButton',
  props: {
    noMargin: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Label'
    },
    danger: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Array,
      default: () => []
    },
    color: {
      type: String,
      default: 'standard'
    }
  }
});
