<template>
  <div class="divider"></div>
</template>
<style scoped>
.divider {
  border-top: 1px solid gray;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
}
</style>