import { io, Socket } from "socket.io-client";

export class SocketIO {
  private static instance: SocketIO;
  private socket: Socket;
  private url = process.env.NODE_ENV === 'production' ? 'https://scpehuen.cl' : `http://localhost:5001`;
  private socketHandler: any;
  constructor() {
    this.socket = io(this.url, { transports: ["websocket"], auth: { token: this.getToken() } });

    const cb = () => this.autoConnectSocket();
    window.removeEventListener('focus', cb);
    window.addEventListener('focus', cb);
  }
  public static getInstance(): SocketIO {
    if (!SocketIO.instance) {
      SocketIO.instance = new SocketIO();
    }
    return SocketIO.instance;
  }
  public connectToServer(): void {
    this.socket.connect();
    // this.socket.on('connect', () => {
    //   console.log('SOCKET CONNECTED !');
    // });
  }
  public disconnectServer():void {
    this.socket.disconnect();
  }
  private getToken(): string {
    const token = localStorage.getItem('token');
    return token ? token : '';
  }
  private autoConnectSocket() {
    console.log('CHEKING CNNECTION SOCKET...');
    if (!this.socket || this.socket.disconnected) {
      console.log('TRYING TO RECONNECT SOCKET...');
      this.connectToServer();
    }
  }
  public joinRoom(room: string, payload: { evt: string, cb: (param: any) => void }[]): void {
    this.socket.emit('join-room', room);
    payload.forEach(el => {
      this.socket.on(el.evt, (data: any) => {
        console.log('SOCKET !');
        el.cb(data);
      });
    });
  }
  public leaveRoom(room: string): void {
    this.socket.emit('leave-room', room );
  }
}