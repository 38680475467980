import { createApp, App as Application } from 'vue';
import App from './App.vue';
import router from './router';
import Harlem from '@harlem/core';
import createDevtoolsPlugin from '@harlem/plugin-devtools';
import createStoragePlugin from '@harlem/plugin-storage';
import  VueGoogleMaps from '@fawmi/vue-google-maps';

// const { GoogleMap, Marker } = Global.Vue3GoogleMap;

import UIDivider from '@/components/UI/UIDivider.vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faTimes,
  faArrowCircleLeft,
  faArrowCircleRight,
  faChevronRight,
    faChevronUp,
    faChevronDown,
  faCheck,
  faExclamationTriangle,
  faCaretDown,
    faChevronLeft,
  faFileDownload,
    faSpinner,
    faImage,
    faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons';
import {
  faEye,
  faEyeSlash,
  faFileExcel,
} from '@fortawesome/free-regular-svg-icons';
library.add(
  faTimes,
  faCheck,
  faEye,
  faEyeSlash,
  faFileExcel,
  faArrowCircleLeft,
  faArrowCircleRight,
  faChevronRight,
  faExclamationTriangle,
  faCaretDown,
  faFileDownload,
    faSpinner,
    faChevronLeft,
    faChevronUp,
    faChevronDown,
    faImage,
    faMapMarkerAlt
);

import moment from 'moment-timezone';
moment.tz.setDefault('America/Santiago');
moment.locale('es');

import './css/tailwind.css';
import './css/globals.css';
import './css/animations.css';

const storagePlugin = createStoragePlugin('*', {
  type: 'local',
  prefix: 'pehuen',
  sync: true
});

const plugins: any[] = [storagePlugin];
if (process.env.NODE_ENV === 'development') {
  plugins.push(
    createDevtoolsPlugin({
      label: 'State'
    })
  );
}

const app: Application = createApp(App);
app.component('divider', UIDivider);
app.component('fa-icon', FontAwesomeIcon);
app.use(VueGoogleMaps, {
  load: {
  key: process.env.VUE_APP_MAP_KEY,
  },
}).use(router).use(Harlem, { plugins }).mount('#app');
